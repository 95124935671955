import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./AboutText";

const AboutText = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container item xs={10}>
        <Typography variant="body1" className={classes.title}>
          <strong>Who am I?</strong>
          <br />
          As a musician, music really is my biggest passion in life and this has
          helped me realize that the intersection between creativity and logic is
          what makes software development the most stimulating work I can
          imagine. I highly value helping others both in and outside of work.
          Even though I have worked with both big and small companies, my main
          focus has always been people. I enjoy making order out of chaos and I
          always try to learn what I can from what I have.
          <br />
          <br />
          <strong>Why choose me?</strong>
          <br />
          I do take a lot of pride in my curiosity, and as my own biggest
          critic, I always wish to improve myself.
          <br />I also believe that my social skills is what sets me apart and
          what makes me a great fit for you. I do believe that communication is
          very important in a professional environment and being able to
          communicate with anyone is equally as important as being able to
          program.
        </Typography>
      </Grid>
    </>
  );
};

export default AboutText;
