import makeStyles from "@material-ui/core/styles/makeStyles";
import Image from "../../img/fern.avif";

const useStyles = makeStyles(theme => ({
    background: {
        background: `url(${Image})`,
        backgroundSize: "100%",
        minHeight: '100vh',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }
}));

export { useStyles };